import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaRegHandPointRight, FaRegUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { addDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';


const AddServiceProvider = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState({});
    const [validated, setValidated] = useState(false);

    const subadminid = localStorage.getItem('subadmindata');

    useEffect(() => {
        if (subadminid) {
            navigate('/add-subadmin-service-provider');
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddChanel = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            addDataAPI('sub-admin-add-service-provider', values).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-service-provider");
                    toast.success("Service has been added successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("This Process already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::ADD Service Provider</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaRegUser className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Service Provider</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleAddChanel}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Provider Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Provider Name"
                                            required
                                            name="service_provider_name"
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid provider name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationMobile">
                                        <Form.Label>Provider URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Provider URL"
                                            required
                                            name="service_provider_url"
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid provider url
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Add</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddServiceProvider