import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { Table, Image } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { activeStatus, fetchAllDataByIDAPI, removeDataFromList } from '../../utils/api'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'

const Agent = () => {
    const [isLoading, setIsloading] = useState(false);
    const subadminid = localStorage.getItem("subadmindata");
    const [agent, setAgent] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (subadminid) {
            navigate('/all-subadmin-agent');
            getAllAgent()
        }
        else {
            navigate("/");
        }

    }, [subadminid])

    const getAllAgent = () => {
        setIsloading(true);
        fetchAllDataByIDAPI('sub-admin-get-all-agent').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false)
                setAgent(...[res.data.agentdata]);
            }
            else {
                setIsloading(false)
                toast.warning("Check your connection")
            }
        })
    }

    const handleAgentRemove = (agentid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('center-remove-center-agent-data', agentid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllAgent();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleAgentActive = (agentid) => {
        activeStatus('center-active-center-agent-status', agentid).then((res) => {
            if (res.data.status === 1) {
                getAllAgent();
            }
        })
    }
    const handleAgentInactive = (agentid) => {
        activeStatus('center-inactive-center-agent-status', agentid).then((res) => {
            if (res.data.status === 1) {
                getAllAgent();
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ALL AGENTS</title>
            </Helmet>

            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Agents</li>
                                </ol>
                            </nav>
                        </div>
                        <Link to="/add-subadmin-agent" className="btn btn-md btn-info text-white"><FaPlus /> Add Agent</Link>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="table-responsive">
                                <Table size="sm" responsive="lg" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Center Name</th>
                                            <th>Create Date</th>
                                            <th>Last Login</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            [...Array(6)].map((_, i) => (
                                                <tr key={i}>
                                                    {Array.from({ length: 6 }).map((_, index) => (
                                                        <td key={index}>
                                                            <Skeleton animation="wave" variant="text" />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        ) :
                                            agent?.map((agent, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{agent?.agent_name}</td>
                                                    <td><span className="badge bg-secondary">{agent?.center_name}</span></td>
                                                    <td>{moment(agent?.agent_create_date).format('Do MMM YYYY, h:mm A')}</td>
                                                    <td>{moment(agent?.agent_last_login).format('Do MMM YYYY, h:mm A')}</td>
                                                    <td>
                                                        {(() => {
                                                            if (agent?.agent_status === "Active") {
                                                                return (
                                                                    <button className="badge bg-success btnstatus" onClick={() => handleAgentActive(agent?.center_agent_id)}>Active</button>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <button className="badge bg-danger btnstatus" onClick={() => handleAgentInactive(agent?.center_agent_id)}>Inactive</button>
                                                                )
                                                            }
                                                        })()}
                                                    </td>
                                                    <td className="btnsection">
                                                        <Link to={`/edit-subadmin-agent/${agent?.center_agent_id}`} className="btnEdit"><FaEdit /></Link>
                                                        <button className="btnDelete" onClick={() => handleAgentRemove(agent?.center_agent_id)}><FaTrashAlt /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Center Name</th>
                                            <th>Create Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </Table>

                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Agent