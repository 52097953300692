import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaRegHandPointRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { FcBusinessman } from 'react-icons/fc';
import { addFormData, fetchAllDataAPI, fetchCityNameByState, getDataAPIToPincode } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';

const AddDealer = () => {
    const [validated, setValidated] = useState(false);
    const [values, setDealerValue] = useState();
    const navigate = useNavigate();
    const subadminid = localStorage.getItem("subadmindata");
    const [state, setState] = useState([]);
    const [cityname, setCityName] = useState([]);
    const [statename, setStateName] = useState("");

    useEffect(() => {
        if (subadminid) {
            navigate('/add-subadmin-dealer');
            getAllDeliveryState()
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getAllDeliveryState = () => {
        fetchAllDataAPI('agent-get-all-delivery-state-data').then((resp) => {
            if (resp.data.status === 1) {
                setState(...[resp.data.statedata])
            }
        })
    }
    const handleSelectState = (e) => {
        setStateName(e.target.value)
        const statename = e.target.value;
        fetchCityNameByState('agent-get-city-name-by-state-data', statename).then((res) => {
            if (res.data.status === 1) {
                setCityName(...[res.data.citydata])
            }
        })
    }

    const handleDealerSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const dealerdata = { warehouseid: subadminid, dealer_name: values.dealer_name, dealer_code: values.dealer_code, dealer_mobile: values.dealer_mobile, dealer_pincode: values.dealer_pincode, dealer_city: values.dealer_city, dealer_state: statename, dealer_password: values.dealer_password, dealer_country: "India" };
                addFormData('sub-admin-add-dealer', dealerdata).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Dealer has been added successfully!");
                        navigate('/all-subadmin-dealer');
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Dealer already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };


    const handleInputs = e => {
        setDealerValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ADD DEALER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add Dealer</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FcBusinessman className="formIcons" />
                                        <h5 className="mb-0 text-danger">Add Dealer</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleDealerSubmit} method="post">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Dealer Name"
                                                name="dealer_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Label>Dealer Code</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Dealer Code"
                                                name="dealer_code"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid code
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Dealer Mobile"
                                                name="dealer_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer mobile
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                                            <Form.Label>Pincode</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Pincode"
                                                name="dealer_pincode"
                                                onChange={handleInputs}
                                                pattern="[0-9]{6}"
                                                maxLength={6}
                                                minLength={6}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer pincode
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom06">
                                            <Form.Label>State</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the State"
                                                name="dealer_state"
                                                onChange={handleSelectState}>
                                                <option value="">Select State</option>
                                                {state?.map((dstate, index) => (
                                                    <option key={index} value={dstate?.state_name}>{dstate?.state_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer state
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom05">
                                            <Form.Label>City</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the City"
                                                name="dealer_city"
                                                onChange={handleInputs}>
                                                <option value="">Select City</option>
                                                {cityname?.map((cityname, index) => (
                                                    <option key={index} value={cityname?.district}>{cityname?.district}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer city
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom08">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                required
                                                type="password"
                                                placeholder="Password"
                                                name="dealer_password"
                                                onChange={handleInputs}
                                                minLength={5}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid password
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit">Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddDealer