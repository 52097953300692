import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaCity, FaLocationDot } from 'react-icons/fa6'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaHouseDamage } from 'react-icons/fa'
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import { GiIndiaGate } from "react-icons/gi";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { editDataFromList, fetchAllDataAPI, getDataAPIToPincode, updateDataAPI } from '../utils/api';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';

const initialState = {
    center_name: '',
    center_mobile: '',
    center_email: '',
    center_pincode: '',
    center_city: '',
    center_state: '',
    center_country: '',
    center_address: '',
    center_company: '',
}


const EditAccount = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [pindata, setPindata] = useState([]);
    const [hiddend, setHiddenValue] = useState({});
    const [company, setCompany] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getSingleAccount(slug)
        getActiveCompany()
    }, [slug])

    const getActiveCompany = () => {
        fetchAllDataAPI('sub-admin-get-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company])
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }
    const getSingleAccount = (slug) => {
        editDataFromList(`sub-admin-get-single-account-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.accountdata])
            }
        })
    }
    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('sub-admin-get-pincode-data', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, account_city: res?.data.pincode.city_name, account_state: res?.data.pincode.state_name, account_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }

    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateAccount = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const data = { account_name: values.account_name, account_mobile: values.account_mobile, account_email: values.account_email, account_pincode: values.account_pincode, account_city: hiddend.account_city, account_state: hiddend.account_state, account_country: hiddend.account_country, account_address: values.account_address, account_company: values.account_company }
            updateDataAPI(`sub-admin-update-account-data/${slug}`, data).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-accounts");
                    toast.success("Account has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Account already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:Edit ACCOUNT</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Account</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateAccount}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationAccountName">
                                        <Form.Label>User Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaHouseDamage /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="User Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_name"
                                                onChange={handleInputs}
                                                value={values?.account_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid user name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterEmail">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiTwotoneMail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter the email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_email"
                                                onChange={handleInputs}
                                                value={values?.account_email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterMobile">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiFillMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the mobile number"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                                value={values?.account_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterPincode">
                                        <Form.Label>Pincode</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbMapPinCode /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the pincode"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_pincode"
                                                pattern="[0-9]*"
                                                onKeyUp={handlePincode}
                                                onChange={handleInputs}
                                                value={values?.account_pincode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCity">
                                        <Form.Label>City</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaCity /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_city"
                                                readOnly={true}
                                                value={pindata?.city_name ? pindata?.city_name: values?.account_city}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomState">
                                        <Form.Label>State</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="State"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_state"
                                                readOnly={true}
                                                value={pindata?.state_name ? pindata?.state_name: values?.account_state}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCountry">
                                        <Form.Label>Country</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><GiIndiaGate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Country"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_country"
                                                readOnly={true}
                                                value={pindata?.country_name ? pindata?.country_name: values?.account_country}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationAccountCompany">
                                        <Form.Label>Select Company</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Select aria-label="Default select example" onChange={handleInputs} name="account_company" required>
                                                {company?.map((company, index) => (
                                                    (() => {
                                                        if (values?.account_company === company?.company_id) {
                                                            return (
                                                                <option selected value={company?.company_id} key={index}>{company?.company_name}</option>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <option value={company?.company_id} key={index}>{company?.company_name}</option>
                                                            )
                                                        }
                                                    })()
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid company name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCenterAddress">
                                        <Form.Label>Address</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLocationDot /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter the full address"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="account_address"
                                                onChange={handleInputs}
                                                value={values?.account_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Update</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditAccount