import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../../utils/api'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'
import { Badge } from 'react-bootstrap'

const ServiceProvider = () => {
    const [service, setService] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const subadminid = localStorage.getItem('subadmindata');
    const navigate = useNavigate();

    useEffect(() => {
        if (subadminid) {
            navigate('/all-subadmin-service-provider');
            getAllService()
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getAllService = () => {
        setIsLoading(true)
        fetchAllDataAPI('sub-admin-all-service-provider').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setService(...[res.data.servicedata]);
            }
            else {
                setIsLoading(false);
                setService("");
            }
        })
    }
    const handleServiceProviderActive = (processid) => {
        activeStatus('sub-admin-active-service-provider-status', processid).then((res) => {
            if (res.data.status === 1) {
                getAllService()
            }
        })
    }
    const handleServiceProviderInactive = (processid) => {
        activeStatus('sub-admin-inactive-service-provider-status', processid).then((res) => {
            if (res.data.status === 1) {
                getAllService()
            }
        })
    }
    const handleServiceProviderRemove = (processid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('sub-admin-remove-service-provider', processid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllService()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>Satkartar::Subadmin::Service Provider</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between mb-2">
                        <h5 className="mb-0 text-uppercase">All Service Provider</h5>
                        <Link to="/add-subadmin-service-provider" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Service Provider</Link>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>URL</th>
                                    <th>Total Today Call</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                            ))}
                                        </tr>
                                    </>
                                    :
                                    service ?
                                        service?.map((service, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{service?.service_provider_name}</td>
                                                <td>{service?.service_provider_url}</td>
                                                <td><Badge>{service?.service_call_counter}</Badge></td>
                                                <td>
                                                    {(() => {
                                                        if (service?.service_provider_status === "Active") {
                                                            return (
                                                                <span className="badge bg-success statustype" onClick={() => handleServiceProviderActive(service?.service_id)}>{service?.service_provider_status}</span>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <span className="badge bg-danger statustype" onClick={() => handleServiceProviderInactive(service?.service_id)}>{service?.service_provider_status}</span>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    <Link to={`/edit-subadmin-service-provider/${service?.service_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                    <button className="btnDelete" onClick={() => handleServiceProviderRemove(service?.service_id)}><AiFillDelete /></button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={6} className="text-center">Data not founds...</td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>URL</th>
                                    <th>Total Today Call</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default ServiceProvider