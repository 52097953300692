import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaRegHandPointRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { FcBusinessman } from 'react-icons/fc';
import { editDataFromList, fetchAllDataByIDAPI, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';

const initialState = {
    centerid: '',
    agent_member_id: '',
    agent_name: '',
    agent_emp_id: '',
}

const EditAgent = () => {
    const [validated, setValidated] = useState(false);
    const [values, setAgent] = useState(initialState);
    const navigate = useNavigate();
    const subadminid = localStorage.getItem("subadmindata");
    const [member, setMember] = useState([]);
    const [center, setCenter] = useState([]);
    const [centerid, setCenterID] = useState("");
    const { slug } = useParams();

    useEffect(() => {
        if (subadminid) {
            navigate(`/edit-subadmin-agent/${slug}`);
            getSingleMemberData(slug)
            getAllCenter()
        }
        else {
            navigate("/");
        }
    }, [subadminid, slug, navigate]);

    const getSingleMemberData = (slug) => {
        editDataFromList(`sub-admin-single-agent-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setAgent(...[res.data.agentdata])
            }
        })
    }
    const getAllCenter = () => {
        fetchAllDataByIDAPI('super-admin-all-center').then((res) => {
            if (res.data.status === 1) {
                setCenter(...[res.data.centerdata]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    const handleSelectCenter = (e) => {
        setCenterID(e.target.value)
        const centerid = e.target.value;
        setAgent((prevState) => ({
            ...prevState,
            centerid: centerid,
        }));
        fetchAllDataByIDAPI(`center-all-center-member-data/${centerid}`).then((res) => {
            if (res.data.status === 1) {
                setMember(...[res.data.memberdata]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
   
    const handleUpdateAgent = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            updateDataAPI(`center-update-center-agent-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-agent");
                    toast.success("Agent has been updated successfully!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }

    const handleInputs = e => {
        setAgent({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:EDIT AGENT</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Edit Agent</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FcBusinessman className="formIcons" />
                                        <h5 className="mb-0 text-danger">Edit Agent</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleUpdateAgent} method="post">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Label>Select Center</Form.Label>
                                            <Form.Select
                                                required
                                                name="centerid"
                                                onChange={handleSelectCenter}
                                                value={values?.centerid || ""}
                                            >
                                                <option value="" disabled>
                                                    Select Center
                                                </option>
                                                {center?.map((center, index) => (
                                                    <option key={index} value={center?.center_id}>
                                                        {center?.center_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Label>Select Member</Form.Label>
                                            <Form.Select required
                                                placeholder="Member Type"
                                                name="agent_member_id"
                                                onChange={handleInputs}>
                                                <option value={values?.agent_member_id}>{values?.agent_member_id}</option>
                                                {member?.map((member, index) => (
                                                    <option key={index} value={member?.center_mis_id}>{member?.mis_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid member
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Agent Name"
                                                name="agent_name"
                                                onChange={handleInputs}
                                                value={values?.agent_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a agent name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                                            <Form.Label>EMP ID</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Employee ID"
                                                name="agent_emp_id"
                                                onChange={handleInputs}
                                                pattern="[^\s]+"
                                                value={values?.agent_emp_id}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid employee ID without space
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit">Update</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditAgent