import React, { useState, useEffect } from 'react';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSingleDataFromDatabase } from '../utils/api';
import DashboardSection from '../components/dashboard/DashboardSection';
import DashboardOverview from '../components/dashboard/DashboardOverview';
import NguccCallCounter from '../components/dashboard/NguccCallCounter';

const Dashboard = () => {
    const navigate = useNavigate();
    const subadminid = localStorage.getItem("subadmindata");
    const [subadmin, setSubadmin] = useState(null);

    useEffect(() => {
        if (subadminid) {
            navigate('/dashboard');
            getDashboardSingleData(subadminid);
        } else {
            navigate("/");
        }
    }, [subadminid, navigate]);

    const getDashboardSingleData = (subadminid) => {
        getSingleDataFromDatabase(`sub-admin-get-single-data/${subadminid}`).then((res) => {
            if (res.data.status === 1) {
                setSubadmin(res.data.subadmindata);
            } else {
                toast.error("Check your connection");
            }
        }).catch(() => {
            toast.error("Unable to fetch data");
        });
    };

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:SUBADMIN::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    const today = new Date();
                    const curHr = today.getHours();
                    if (curHr < 12) {
                        return (
                            <h4 className="dashboardname">
                                Good Morning, <span>{subadmin?.subadmin_name}</span>
                            </h4>
                        );
                    } else if (curHr < 17) {
                        return (
                            <h4 className="dashboardname">
                                Good Afternoon, <span>{subadmin?.subadmin_name}</span>
                            </h4>
                        );
                    } else {
                        return (
                            <h4 className="dashboardname">
                                Good Evening, <span>{subadmin?.subadmin_name}</span>
                            </h4>
                        );
                    }
                })()}

                <div className="">
                    <DashboardSection />
                </div>
                <div className="">
                    <DashboardOverview />
                </div>
            </ContentWrapper>
        </div>
    );
};

export default Dashboard;
