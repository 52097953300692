import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaPlus, FaRegEdit, FaRegEye, FaRegTrashAlt } from 'react-icons/fa';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { GrDocumentUpdate } from "react-icons/gr";
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import { activeStatus, addFormData, fetchAllDataByIDAPI, getSingleDataFromDatabase, removeDataFromList } from '../../utils/api';
import { numberFormat } from '../../components/numberFormat.js';

const Product = () => {
    const [product, setProduct] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const subadminid = localStorage.getItem("subadmindata");
    const [qtyvalues, setQuantityValue] = useState();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = product.slice(firstIndex, lastIndex);
    const npage = Math.ceil(product.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    const [show, setShow] = useState(false);
    const [productid, setProductId] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (pid) => {
        setShow(true);
        setProductId((pid));
    }
    useEffect(() => {
        if (subadminid) {
            navigate('/all-subadmin-products');
            getAllProducts()
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getAllProducts = () => {
        setIsloading(true);
        fetchAllDataByIDAPI(`sub-admin-get-all-products`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setProduct(...[res.data.product]);
            }
            else {
                setIsloading(false);
            }
        })
    }

    const handleProductRemove = (pid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('sub-admin-remove-product-data', pid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllProducts()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleProductActive = (pid) => {
        activeStatus('sub-admin-active-product-status', pid).then((res) => {
            if (res.data.status === 1) {
                getAllProducts()
            }
        })
    }
    const handleProductInactive = (pid) => {
        activeStatus('sub-admin-inactive-product-status', pid).then((res) => {
            if (res.data.status === 1) {
                getAllProducts()
            }
        })
    }
    const [validated, setValidated] = useState(false);

    const handleUpdateQuantityForm = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const data = { productid: productid, p_upd_qty: qtyvalues.p_upd_qty }
                addFormData('sub-admin-add-product-quantity', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Quantity has been added successfully!");
                        getAllProducts()
                        setShow(false)
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };
    const handleInputs = e => {
        setQuantityValue({
            ...qtyvalues,
            [e.target.name]: e.target.value,
        });
    };
    const [detailshow, setDetailShow] = useState(false);
    const handleDetailClose = () => setDetailShow(false);
    const [pdetails, setPdetails] = useState([]);
    const [phistory, setPHistory] = useState([]);
    const handleDetailShow = (pid) => {
        setDetailShow(true);
        getSingleDataFromDatabase(`sub-admin-get-single-product/${pid}`).then((res) => {
            if (res.data.status === 1) {
                setPdetails(...[res.data.product]);
                setPHistory(...[res.data.phistory]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ALL PRODUCTS</title>
            </Helmet>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleUpdateQuantityForm}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Add Quantity</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Add Quntity"
                                    name="p_upd_qty"
                                    pattern="[0-9]*"
                                    onChange={handleInputs}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid product quantity.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button type="submit" className="btnupdate btn-sm">Update Quantity</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={detailshow}
                onHide={() => handleDetailClose(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="productdetails">
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Company</span>
                                    <span className="pvalue">{pdetails.company_name}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Category</span>
                                    <span className="pvalue">{pdetails.cat_name}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Name</span>
                                    <span className="pvalue">{pdetails.product_name}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Price</span>
                                    <span className="pvalue">₹ {pdetails.product_price}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Sell Price</span>
                                    <span className="pvalue">₹ {pdetails.product_sell_price}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product SKU Number</span>
                                    <span className="pvalue">{pdetails.product_sku_number}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Tax Rate</span>
                                    <span className="pvalue">₹ {pdetails.product_tax_rate}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Discount Rate</span>
                                    <span className="pvalue">₹ {pdetails.product_disc_rate}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Height(CM)</span>
                                    <span className="pvalue">{pdetails.product_height}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Width(CM)</span>
                                    <span className="pvalue">{pdetails.product_width}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Bredth(CM)</span>
                                    <span className="pvalue">{pdetails.product_bredth}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Length(CM)</span>
                                    <span className="pvalue">{pdetails.product_length}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Weight(gm)</span>
                                    <span className="pvalue">{pdetails.product_weight}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Total QTY</span>
                                    <span className="pvalue">{pdetails.product_total_qty}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Manufacturing Date</span>
                                    <span className="pvalue badge badge-warning">{moment(pdetails.product_mnf_date).format('Do MMMM YYYY')}{ }</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Expiry Date</span>
                                    <span className="pvalue badge badge-danger">{moment(pdetails.product_exp_date).format('Do MMMM YYYY')}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product BAR CODE</span>
                                    <span className="pvalue">{pdetails.product_bar_code}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Select Product Form</span>
                                    <span className="pvalue">{pdetails.product_form}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Supplier Name</span>
                                    <span className="pvalue">{pdetails.product_supplier}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Shipping Charge</span>
                                    <span className="pvalue">₹ {pdetails.product_shipping_charge}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product HSN Code</span>
                                    <span className="pvalue">{pdetails.product_hsn_code}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="12">
                                <div className="detailsdiv">
                                    <span className="pname">Product Description</span>
                                    <span className="pvalue">{pdetails.product_desc}</span>
                                </div>
                            </Col>
                        </Row>
                        <div className="detailsheading">
                            <h5>Product History</h5>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Add Quantity</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {phistory?.map((phistory, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{phistory?.product_history_qty}</td>
                                        <td><span className="badge bg-success">{moment(phistory?.history_date).format('Do MMMM YYYY')}</span></td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Products</li>
                                </ol>
                            </nav>
                        </div>
                        <Link to="/add-subadmin-products" className="btn btn-md btn-info text-white"><FaPlus /> Add Product</Link>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="table-responsive">
                                <Table responsive="sm" size="sm" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Comp Name</th>
                                            <th>Cat Name</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Sell Price</th>
                                            <th>SKU</th>
                                            <th>Current QTY</th>
                                            <th>Total QTY</th>
                                            <th>HSN Number</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            [...Array(6)].map((_, i) => (
                                                <tr key={i}>
                                                    {Array.from({ length: 13 }).map((_, index) => (
                                                        <td key={index}>
                                                            <Skeleton animation="wave" variant="text" />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        ) :
                                            records?.map((records, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{records?.company_name}</td>
                                                    <td>{records?.cat_name}</td>
                                                    <td>{records?.product_name}</td>
                                                    <td>{numberFormat(records?.product_price)}</td>
                                                    <td>{numberFormat(records?.product_sell_price)}</td>
                                                    <td>{records?.product_sku_number}</td>
                                                    <td>{records?.product_current_qty}</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="qtybadge badge bg-secondary">{records?.product_total_qty}</span>
                                                            <button className="updatebtn badge bg-success" onClick={() => handleShow(records.product_id)}><GrDocumentUpdate style={{ fontSize: "15px" }} /></button>
                                                        </div>
                                                    </td>
                                                    <td>{records?.product_hsn_code}</td>
                                                    {(() => {
                                                        if (records?.product_status === "Active") {
                                                            return (
                                                                <td><button className="badge bg-success btnstatus" onClick={() => handleProductActive(records?.product_id)}>{records?.product_status}</button></td>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <td><button className="badge bg-danger btnstatus" onClick={() => handleProductInactive(records?.product_id)}>{records?.product_status}</button></td>
                                                            )
                                                        }
                                                    })()}
                                                    <td>
                                                        <button className="actionbtn badge bg-warning" onClick={() => handleDetailShow(records.product_id)}><FaRegEye style={{ fontSize: "15px" }} /></button>
                                                        <Link to={`/edit-subadmin-products/${records.product_id}`} className="actionbtn badge bg-info"><FaRegEdit style={{ fontSize: "15px" }} /></Link>
                                                        <button className="actionbtn badge bg-danger" onClick={() => handleProductRemove(records.product_id)}><FaRegTrashAlt style={{ fontSize: "15px" }} /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Comp Name</th>
                                            <th>Cat Name</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Sell Price</th>
                                            <th>SKU</th>
                                            <th>Current QTY</th>
                                            <th>Total QTY</th>
                                            <th>HSN Number</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <nav>
                                    <ul className="pagination justify-content-end round-pagination">
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                        </li>
                                        {
                                            numbers.map((n, i) => (
                                                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                    <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                                </li>
                                            ))
                                        }
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default Product