import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../utils/api'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Table } from 'react-bootstrap'

const Account = () => {
    const [account, setAccount] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const subadminid = localStorage.getItem("subadmindata");
    const navigate = useNavigate();

    useEffect(() => {
        if (subadminid) {
            navigate('/all-subadmin-accounts');
            getAllAccount()
        }
        else {
            navigate("/");
        }

    }, [subadminid])

    const getAllAccount = () => {
        setIsLoading(true)
        fetchAllDataAPI('sub-admin-all-account').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setAccount(...[res.data.accountdata]);
            }
            else {
                setIsLoading(false);
                toast.warning("Check your connection");
            }
        })
    }
    const handleAccountActive = (accountid) => {
        activeStatus('sub-admin-active-account-status', accountid).then((res) => {
            if (res.data.status === 1) {
                getAllAccount()
            }
        })
    }
    const handleAccountInactive = (accountid) => {
        activeStatus('sub-admin-inactive-account-status', accountid).then((res) => {
            if (res.data.status === 1) {
                getAllAccount()
            }
        })
    }
    const handleAccountRemove = (accountid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('sub-admin-remove-account', accountid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllAccount()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:ACCOUNTS</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between">
                        <h5 className="mb-0 text-uppercase">All Accounts</h5>
                        <Link to="/add-subadmin-accounts" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Account</Link>
                    </div>
                    <div className="table-responsive">
                        <Table responsive="sm" size="sm" striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    [...Array(6)].map((_, i) => (
                                        <tr key={i}>
                                            {Array.from({ length: 10 }).map((_, index) => (
                                                <td key={index}>
                                                    <Skeleton animation="wave" variant="text" />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) :
                                    account?.map((account, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{account?.account_name}</td>
                                            <td>{account?.account_email}</td>
                                            <td>{account?.account_mobile}</td>
                                            <td>{account?.company_name}</td>
                                            <td>{account?.account_city}</td>
                                            <td>{account?.account_state}</td>
                                            <td>{account?.account_country}</td>
                                            <td>
                                                {(() => {
                                                    if (account?.account_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success hrstatus" onClick={() => handleAccountActive(account?.account_id)}>{account?.account_status}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger hrstatus" onClick={() => handleAccountInactive(account?.account_id)}>{account?.account_status}</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-subadmin-accounts/${account?.account_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleAccountRemove(account?.account_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Account