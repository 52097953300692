import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillMobile, AiTwotoneMail } from 'react-icons/ai'
import { FaCity, FaLocationDot } from 'react-icons/fa6'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaHouseDamage } from 'react-icons/fa'
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import { GiIndiaGate } from "react-icons/gi";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { addFormData, fetchAllDataAPI, getDataAPIToPincode } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';


const AddCenter = () => {
    const navigate = useNavigate();
    const [pindata, setPindata] = useState([]);
    const [values, setUserValue] = useState({});
    const [hiddend, setHiddenValue] = useState({});
    const [company, setCompany] = useState([]);
    const [validated, setValidated] = useState(false);

    const subadminid = localStorage.getItem("subadmindata");

    useEffect(() => {
        if (subadminid) {
            navigate('/add-subadmin-center');
            getActiveCompany()
        }
        else {
            navigate("/");
        }

    }, [subadminid])


    const getActiveCompany = () => {
        fetchAllDataAPI('super-admin-get-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company])
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }

    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, center_city: res?.data.pincode.city_name, center_state: res?.data.pincode.state_name, center_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddCenter = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const data = { center_name: values.center_name, center_mobile: values.center_mobile, center_email: values.center_email, center_pincode: values.center_pincode, center_city: hiddend.center_city, center_state: hiddend.center_state, center_country: hiddend.center_country, center_address: values.center_address, center_company: values.center_company }
            addFormData('super-admin-add-center', data).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-center");
                    toast.success("Center has been added successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Center already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUPER ADMIN::ADD CENTER</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Center</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleAddCenter}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCenterName">
                                        <Form.Label>Center Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaHouseDamage /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Center Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterEmail">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiTwotoneMail /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter the email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_email"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterMobile">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><AiFillMobile /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the mobile number"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid center mobile number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterPincode">
                                        <Form.Label>Pincode</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbMapPinCode /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the pincode"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_pincode"
                                                pattern="[0-9]*"
                                                onKeyUp={handlePincode}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCity">
                                        <Form.Label>City</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaCity /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_city"
                                                readOnly={true}
                                                value={pindata?.city_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomState">
                                        <Form.Label>State</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="State"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_state"
                                                readOnly={true}
                                                value={pindata?.state_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomCountry">
                                        <Form.Label>Country</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><GiIndiaGate /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Country"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_country"
                                                readOnly={true}
                                                value={pindata?.country_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCenterCompany">
                                        <Form.Label>Select Company</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><TbBuildingEstate /></InputGroup.Text>
                                            <Form.Select aria-label="Default select example" onChange={handleInputs} name="center_company" required>
                                                <option value="">Select company</option>
                                                {company?.map((company, index) => (
                                                    <option value={company?.company_id} key={index}>{company?.company_name}</option>
                                                ))}

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid company name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCenterAddress">
                                        <Form.Label>Address</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLocationDot /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter the full address"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="center_address"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid address.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Add</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddCenter